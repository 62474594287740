import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import LoadingScreen from './animations/LoadingScreen';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import PageTransition from './animations/PageTransition';

// Lazy loading des pages
const Acceuil = lazy(() => import('./pages/Acceuil'));
const NosChalets = lazy(() => import('./pages/Nos-chalets'));
const Contact = lazy(() => import('./pages/Contact'));

// Lazy loading des services
const PiscineDetente = lazy(() => import('./components/services/Piscine-detente'));
const PetitDejeuner = lazy(() => import('./components/services/Petit-dejeuner'));
const VeloBarbecue = lazy(() => import('./components/services/Velo-barbecue'));
const BaladeCheval = lazy(() => import('./components/services/Balade-cheval'));
const PlageOstriconi = lazy(() => import('./components/services/Plage-ostriconi'));
const GolfReginu = lazy(() => import('./components/services/Golf-reginu'));
const AubergeTesa = lazy(() => import('./components/services/Auberge-tesa'));

// Composant de chargement pour le lazy loading
const LoadingFallback = () => (
  <div className="loading-container">
    <LoadingScreen />
  </div>
);

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Suspense fallback={<LoadingFallback />}>
        <Routes location={location} key={location.pathname}>
          <Route 
            path="/Acceuil" 
            element={
              <PageTransition>
                <Acceuil />
              </PageTransition>
            } 
          />
          <Route 
            path="/Nos-chalets" 
            element={
              <PageTransition>
                <NosChalets />
              </PageTransition>
            } 
          />
          <Route 
            path="/Contact" 
            element={
              <PageTransition>
                <Contact />
              </PageTransition>
            } 
          />
          <Route
            path="/services/piscine-detente"
            element={
              <PageTransition>
                <PiscineDetente />
              </PageTransition>
            }
          />
          <Route 
            path="/services/petit-dejeuner" 
            element={
              <PageTransition>
                <PetitDejeuner />
              </PageTransition>
            }
          />
          <Route 
            path="/services/velo-barbecue" 
            element={
              <PageTransition>
                <VeloBarbecue />
              </PageTransition>
            }
          />
          <Route 
            path="/services/balade-cheval" 
            element={
              <PageTransition>
                <BaladeCheval />
              </PageTransition>
            }
          />
          <Route 
            path="/services/plage-ostriconi" 
            element={
              <PageTransition>
                <PlageOstriconi />
              </PageTransition>
            }
          />
          <Route 
            path="/services/golf-reginu" 
            element={
              <PageTransition>
                <GolfReginu />
              </PageTransition>
            }
          />
          <Route 
            path="/services/auberge-tesa" 
            element={
              <PageTransition>
                <AubergeTesa />
              </PageTransition>
            }
          />
          <Route 
            path="/" 
            element={
              <PageTransition>
                <Acceuil />
              </PageTransition>
            } 
          />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Header />
      <AnimatedRoutes />
      <Footer />
    </div>
  );
};

export default App;